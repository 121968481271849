import styled from "styled-components"
import { Grid, Card } from "../../Home/HomeResearch/style"
import { XL, LG, BREAKPOINT } from "../../../utils/constants"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  padding: 120px 0 130px;
  @media (max-width: ${BREAKPOINT}) {
    padding: 42px 35px;
  }
`

export const ContentGrid = styled(Grid)`
  grid-gap: 24px;
  max-width: 930px;
  margin: 0 auto;
  @media (max-width: ${XL}) {
    max-width: 100%;
    padding: 0 8.333333%;
  }
  @media (max-width: ${BREAKPOINT}) {
    padding: 0;

    > div {
      padding: 20px 0 0;
    }
  }
`

export const ContentCard = styled(Card)`
  box-shadow: ${props => props.theme.boxShadow};
  padding: 0;
  h4 {
    padding: 48px 21px 53px 40px;
    @media (max-width: ${BREAKPOINT}) {
      padding: 32px 46px;
      font-size: 22px;
    }
  }
`
export const ContentButtons = styled.div`
  background-color: ${props => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  padding: 0 20px;
  border-radius: 0 0 8px 8px;
  align-items: center;
  justify-content: center;

  button:last-child {
    margin-right: 0;
  }

  @media (max-width: ${LG}) {
    button {
      margin: 0;
      max-width: 240px;
    }
  }
  @media (max-width: ${BREAKPOINT}) {
    height: auto;
    padding: 30px 30px 40px;
    display: block;
    button {
      max-width: none;
      width: 100%;
      margin-bottom: 20px;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export const BtnWrapMobile = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  width: 100%;
  text-align: center;

  @media (max-width: ${LG}) {
    display: flex;
    flex-direction: column-reverse;

    button {
      max-width: unset;

      &:first-child {
        display: inline-block;
        margin-top: 18px;
      }
    }
  }
`
export const LastBtnWrap = styled.div`
  margin: 0 auto 0 10px;

  @media (max-width: ${LG}) {
    margin: 0 auto;
  }

  a {
    margin-right: 0 !important;
  }
`
