import styled from "styled-components"

import Blob from "../../../../components/common/Blob/Blob"

export const Wrapper = styled.div`


`

export const AvatarBlob = styled(Blob)`

background: url(${props => props.bg}) no-repeat center center / cover;
border:1px solid red;

`

