import React, { useEffect, useState, Fragment } from "react"
import { Wrapper, Blobs } from "./style"
import { useMediaQuery } from "react-responsive"
import { useStaticQuery, graphql } from "gatsby"

const BlobsMural = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1199px)",
  })

  const {
    allWordpressWpStories: { edges },
  } = useStaticQuery(graphql`
    query BlobsMuralQuery {
      allWordpressWpStories {
        edges {
          node {
            acf {
              video_thumbnail {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const [avatarImages, setAvatarImages] = useState([])

  useEffect(() => {
    edges.forEach(({ node }) =>
      setAvatarImages(prevArray => [
        ...prevArray,
        node.acf.video_thumbnail.source_url,
      ])
    )
  }, [edges])

  return (
    <Wrapper>
      {isDesktop && (
        <Fragment>
          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "30%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "12%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "26vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "28vw"}
            delay={"0"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "30%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "20%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "5.2vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "5.6vw"}
            delay={"200"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "10%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "10%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "6.8vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "6.5vw"}
            bg={avatarImages[32]}
            delay={"250"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "30%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "30%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "5.1vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "4.9vw"}
            bg={avatarImages[23]}
            delay={"400"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "54%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "24%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "5.9vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "5.6vw"}
            bg={avatarImages[29]}
            delay={"500"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "60%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "35%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "7.5vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "7.2vw"}
            bg={avatarImages[28]}
            delay={"700"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "80%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "25%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "3.4vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "3.4vw"}
            bg={avatarImages[13]}
            delay={"850"}
          />

          <Blobs
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "88%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "18%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                ? "15vw"
                : "5.1vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "4.9vw"}
            bg={avatarImages[31]}
            delay={"1000"}
          />
        </Fragment>
      )}
    </Wrapper>
  )
}

export default BlobsMural
