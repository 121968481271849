import React, { Fragment } from "react"
import { Title, PatientsContent } from "./style"
import { Wrapper, ColumnTexture, WhoInfo, Subtitle } from "../Who/style"

import { MainParagraph } from "../../../utils/GlobalComponents"
import BlobsMural from "./BlobsMural/BlobsMural"

const Patients = () => {
  return (
    <PatientsContent>
      <Wrapper>
        <BlobsMural />
        <Fragment>
          <ColumnTexture texture={"2"} />
          <WhoInfo>
            <div>
              <Title data-aos="fade-up" data-aos-duration="1000">
                Who is LymeMIND for?
              </Title>
              <Subtitle
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                For patients and others affected by Lyme disease
              </Subtitle>
              <MainParagraph
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="800"
              >
                We connect patients with the leaders in Lyme disease research to
                build a community to provide support, inform about the latest
                progress, share experience, and advance treatments.
              </MainParagraph>
            </div>
          </WhoInfo>
        </Fragment>
      </Wrapper>
    </PatientsContent>
  )
}

export default Patients
