import styled from "styled-components"
import { BREAKPOINT, LG } from "../../../utils/constants"

import { MainH2, MainParagraph } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  text-align: center;
  padding: 155px 0 60px;
  position: relative;
  
  @media (max-width: ${BREAKPOINT}) {
    padding: 200px 0 46px;
  }
`

export const Title = styled(MainH2)`
  margin: 0 auto 40px;
  max-width: none;
  display: inline;
  position:relative;
  z-index:4;

  &::after {
    content: "";
    display: block;
    line-height: 43px;
    width: 40px;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 30px;
    &::after {
      content: "__";
      display: block;
      line-height: 28px;
    }
  }
`
export const Subtitle = styled(MainParagraph)`
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0;
  max-width: 664px;
  margin: 0 auto 200px;
  position:relative;
  z-index:4;
  @media (max-width: ${BREAKPOINT}) {
    font-size: 22px;
    line-height: 31px;
    max-width: 266px;
    margin-bottom: 300px;
  }
`

export const ContentText = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 26px;
  grid-row-gap: 0px;
  text-align: left;
  max-width: 930px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT}) {
    grid-template-columns: 1fr;
    padding: 0 35px;
  }
  p:first-child {
    grid-area: 1 / 1 / 2 / 2;
    @media (max-width: ${BREAKPOINT}) {
      grid-area: auto;
    }
  }
  p:last-child {
    grid-area: 1 / 2 / 2 / 3;
    @media (max-width: ${BREAKPOINT}) {
      grid-area: auto;
    }
  }
  @media (min-width: ${BREAKPOINT}) and (max-width: ${LG}) {
    max-width: 664px;
  }
`

export const Paragraph = styled(MainParagraph)``
