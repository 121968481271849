import styled from "styled-components"
import { XXL, XL, BREAKPOINT } from "../../../utils/constants"
import { TopTitle } from "../../Home/Team/style"

export const Title = styled(TopTitle)`
  @media (max-width: ${XXL}) {
    width: 514px;
    margin: 0 auto 8px;
    display: block;
  }
  @media (max-width: ${XL}) {
    width: 360px;
  }
  @media (max-width: ${BREAKPOINT}) {
    width: 100%;
    max-width: none;
    padding: 0 35px;
  }
`
export const PatientsContent = styled.div`
  @media (max-width: ${BREAKPOINT}) {
    padding-top: 40px;
    min-height: unset;

    h3 {
      margin-bottom: 20px;
    }

  
  }
`
