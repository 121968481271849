import React, { Fragment, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"
import {
  Wrapper,
  MindGrid,
  Letter,
  ContentMindGrid,
  Subtitle,
  Box,
  BoxText,
  Title,
} from "./style"
import { ContentText, Paragraph } from "../Hero/style"
import SVGIcon from "../../common/SVGIcon"

import Blob from "../../common/Blob/Blob"

const Mind = () => {
  /*   const [isMobile, setIsMobile] = useState(false) */

  const isMobile = useMediaQuery({
    query: "(max-device-width: 780px)",
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1199px)",
  })

  return (
    <Wrapper>
      {isDesktop ? (
        <Fragment>
          <Blob
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "50%"
            }
            toLeft={
              isTabletOrMobileDevice
                ? "40%"
                : isNotebookOrIpad
                  ? "15%"
                  : "100px"
            }
            customWidth={"0"}
            customHeight={isNotebookOrIpad ? "50vw" : "5.4vw"}
          />
          <Blob
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "72%"
            }
            toLeft={
              isTabletOrMobileDevice
                ? "40%"
                : isNotebookOrIpad
                  ? "15%"
                  : "-100px"
            }
            customWidth={"0"}
            customHeight={isNotebookOrIpad ? "50vw" : "21vw"}
          />
        </Fragment>
      ) : (
          <Fragment>
            <Blob
              toTop={
                isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "50%"
              }
              toLeft={
                isTabletOrMobileDevice
                  ? "40%"
                  : isNotebookOrIpad
                    ? "15%"
                    : "100px"
              }
              customWidth={"0"}
              customHeight={isNotebookOrIpad ? "50vw" : "5.4vw"}
            />
            <Blob
              toTop={
                isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "72%"
              }
              toLeft={
                isTabletOrMobileDevice
                  ? "40%"
                  : isNotebookOrIpad
                    ? "15%"
                    : "-100px"
              }
              customWidth={"0"}
              customHeight={isNotebookOrIpad ? "50vw" : "21vw"}
            />
          </Fragment>
        )}

      <Fragment>
        <ContentMindGrid
          data-aos={isMobile ? "fade" : "fade-up"}
          data-aos-duration="1000"
        >
          <MindGrid>
            <Letter
              data-aos={isMobile ? "fade-up" : "fade-down"}
              data-aos-duration="1000"
              data-aos-delay={isMobile ? "0" : "600"}
            >
              <SVGIcon name="m" height={46} fill="white" />
              <span
                data-aos={isMobile ? "fade" : "fade-up"}
                data-aos-duration="1000"
                data-aos-delay={isMobile ? "0" : "1100"}
              >
                Multiscale
                </span>
            </Letter>
            <Letter
              data-aos={isMobile ? "fade-up" : "fade-down"}
              data-aos-duration="1000"
              data-aos-delay={isMobile ? "0" : "800"}
            >
              <SVGIcon name="i" height={46} fill="white" />
              <span
                data-aos={isMobile ? "fade" : "fade-up"}
                data-aos-duration="1000"
                data-aos-delay={isMobile ? "0" : "1300"}
              >
                Integrated
                </span>
            </Letter>
            <Letter
              data-aos={isMobile ? "fade-up" : "fade-down"}
              data-aos-duration="1000"
              data-aos-delay={isMobile ? "0" : "1000"}
            >
              <SVGIcon name="n" height={46} fill="white" />
              <span
                data-aos={isMobile ? "fade" : "fade-up"}
                data-aos-duration="1000"
                data-aos-delay={isMobile ? "0" : "1500"}
              >
                Network
                </span>
            </Letter>
            <Letter
              data-aos={isMobile ? "fade-up" : "fade-down"}
              data-aos-duration="1000"
              data-aos-delay={isMobile ? "0" : "1200"}
            >
              <SVGIcon name="d" height={46} fill="white" />
              <span
                data-aos={isMobile ? "fade" : "fade-up"}
                data-aos-duration="1000"
                data-aos-delay={isMobile ? "0" : "1700"}
              >
                Lyme Disease
                </span>
            </Letter>
          </MindGrid>
        </ContentMindGrid>

        <Subtitle
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          LymeMIND is designed to enable a <strong>systems medicine approach</strong> to identify novel biomarkers and new therapeutic opportunities.
          </Subtitle>
        <Box data-aos="fade-up" data-aos-duration="1000">
          <BoxText>Big Data</BoxText>
          <span className="desktop">
            <SVGIcon name="arrow" width={9} />
            <SVGIcon name="arrow" width={9} />
            <SVGIcon name="arrow" width={9} />
            <SVGIcon name="arrow" width={9} />
          </span>
          <div className="mobile">
            <span>
              <SVGIcon name="arrow" width={9} />
              <SVGIcon name="arrow" width={9} />
              <SVGIcon name="arrow" width={9} />
            </span>
            <span>
              <SVGIcon name="arrow" width={9} />
              <SVGIcon name="arrow" width={9} />
              <SVGIcon name="arrow" width={9} />
            </span>
            <span>
              <SVGIcon name="arrow" width={9} />
              <SVGIcon name="arrow" width={9} />
              <SVGIcon name="arrow" width={9} />
            </span>
          </div>
          <BoxText gradient={"orange"}>Real Knowledge</BoxText>
        </Box>

        <ContentText>
          <Title
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Moving rapidly from “big data” to real knowledge and improved <strong>understanding of Lyme disease</strong>
          </Title>
          <Paragraph
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={isMobile ? "0" : "500"}
          >
            Through LymeMIND’s network of collaborators, we are able to interrogate the complex biology of samples collected from Lyme-affected individuals using molecular profiling technologies and applying advanced statistical and machine learning approaches to move rapidly from “Big Data” to knowledge and improved understanding of the disease.

              <br />
            <br />

              The Icahn School of Medicine at Mount Sinai uses its unique vantage at the nexus of clinical medicine, applied -omics, data science, and digital health to illuminate better understanding, prevention, and treatment of complex diseases.
            </Paragraph>
        </ContentText>
      </Fragment>
    </Wrapper>
  )
}

export default Mind
