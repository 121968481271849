import React, { Fragment } from "react"
import { Wrapper, Title, Text } from "./style"
import Logo from "../../common/Logo"
import { useMediaQuery } from "react-responsive"

import Blob from "../../common/Blob/Blob"

const Solution = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1199px)",
  })

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="1000">
      {isDesktop && (
        <Fragment>
          <Blob
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "80%"
            }
            toRight={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "18%"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                  ? "15vw"
                  : "8.6vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "9.3vw"}
          />
          <Blob
            toTop={
              isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "20%"
            }
            toLeft={
              isTabletOrMobileDevice
                ? "40%"
                : isNotebookOrIpad
                  ? "15%"
                  : "100px"
            }
            customWidth={
              isTabletOrMobileDevice
                ? "75vw"
                : isNotebookOrIpad
                  ? "15vw"
                  : "6.6vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "7.3vw"}
          />
        </Fragment>
      )}

      <Fragment>
        <Title
        >
          A complex disease deserves a <strong>complex solution</strong>
        </Title>
        <Text
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          The Icahn School of Medicine at Mount Sinai is building a multiscale integrated network of Lyme disease (LymeMIND) that represents a unified, predictive model of Lyme disease
          </Text>
        <Logo data-aos="fade-up" data-aos-duration="1000" width={276} />
      </Fragment>
    </Wrapper>
  )
}

export default Solution
