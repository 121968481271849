import styled from "styled-components"

import { LG, XL } from "../../../utils/constants"

import {
  MainH3,
  PrimaryBtn,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 572px 3fr;
  align-items: center;
  justify-items: center;
  grid-template-rows: 780px;

  position: relative;

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    padding-bottom: 0;
    background-color: ${props => props.theme.neutralSilver};
    grid-template-rows: auto;
  }
`

export const HeroImg = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: ${props => (props.desktopImg ? "100%!important" : "0!important")};

  @media (max-width: ${LG}) {
    height: ${props => (props.desktopImg ? "0%!important" : "400px!important")};
  }

  @media (max-width: ${XL}) {
    align-self: flex-start;
    justify-self: baseline;
    width: 80%;
    z-index: 2;
    position: relative;
  }

  @media (max-width: ${LG}) {
    width: 100%;
    display: block;
    height: 400px;
  }
`

export const HeroInfo = styled.div`
  max-width: 510px;
  padding-bottom: 45px;
  margin-left: -30px;
  @media (max-width: ${XL}) {
    margin-left: -100px;
  }

  @media (max-width: ${LG}) {
    background-color: ${props => props.theme.neutralSilver};
    padding-bottom: 0;
    right: 0;
    max-width: unset;
    margin-left: auto;

    img {
      width: 100%;
    }
  }
`

export const Title = styled(MainH3)`
  margin-bottom: 100px;
  position: relative;
  z-index: 3;

  @media (max-width: ${XL}) {
    margin-bottom: 30px;
  }

  @media (max-width: ${LG}) {
    padding-left: 40px;
    padding-top: 100px;
    max-width: unset;
    padding-right: 30px;
  }
`

export const Dates = styled.div`
  padding-top: 20px;
  margin-bottom: 50px;

  @media (max-width: ${LG}) {
    margin-bottom: 0;
    background-color: white;
    padding-bottom: 15px;
    margin-top: -5px;
    padding-left: 40px;
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.navy};
    margin-bottom: 15px;

    @media (max-width: ${LG}) {
      padding-top: 10px;
    }
  }
  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      img {
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: ${props => props.theme.lightBlue};
        margin-left: 15px;
      }
    }
  }
`

export const BtnWrapper = styled.div`
  display: flex;

  @media (max-width: ${LG}) {
    flex-direction: column-reverse;
    align-items: center;
    background-color: white;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const ViewEventBtn = styled(PrimaryBtn)`
  margin-right: 12px;

  @media (max-width: ${LG}) {
    margin-bottom: 15px;
  }
`

export const ResearchBtn = styled(SecondaryBtn)`
  @media (max-width: ${LG}) {
    margin-bottom: 15px;
  }
`
export const RegistrationTitle = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #b0c9cb;
  margin-bottom:5px;

  @media(max-width:${LG}){
    margin-top:5px;
    margin-bottom:-5px;
  }
`
