import styled from "styled-components"
import { Grid } from "../../Home/HomeResearch/style"
import {
  XL,
  LG,
  BREAKPOINT
} from "../../../utils/constants"

export const ContentGrid = styled(Grid)`
  grid-gap: 24px;
  max-width: 1030px;
  margin: 0 auto;
  @media (max-width: ${LG}) {
    max-width: 100%;
    padding: 0 8.333333%;
  }
  @media (max-width: ${BREAKPOINT}) {
    padding: 0;
    > div {
      padding: 20px 0px 0;
    }
  }
`
export const Column = styled.div`
  padding-left: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${XL}) {
    padding-left: 100px;
  }
  @media (max-width: ${LG}) {
    margin-top: 50px;
    padding-left: 0;
  }
  @media (max-width: ${BREAKPOINT}) {
    margin-top: 26px;
    padding: 0 15px;
  }
`

export const NewInfoText = styled.div`
  max-width: 244px;
  margin-bottom: 24px;
  p {
    margin-bottom: 14px;
  }
  @media (max-width: ${LG}) {
    max-width: none;
  }
  @media (max-width: ${BREAKPOINT}) {
    margin-top: 48px;
  }
`

export const BtnWrapMobile = styled.div`
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: ${LG}) {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    width:100%;
  }

  @media (max-width: ${LG}) {
    display: block;
    a {
      &:first-child {
        margin-bottom: 18px;
      }
    }
  }
`
