import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import { ContentGrid, Column, NewInfoText, BtnWrapMobile } from "./style"
import {
  DefaultWrapper,
  MainH4,
  MainParagraph,
  MainTextLink,
  TextLinkWrap,
} from "../../../utils/GlobalComponents"
import { Wrapper, ContentCard, ContentButtons } from "../AboutRegister/style"
import { ButtonVisit } from "../../Home/HomeResearch/style"
import { ViewEventBtn } from "../../Home/Hero/style"

const NewInfo = () => {
  return (
    <Wrapper>
      <DefaultWrapper>
        <Fragment>
          <ContentGrid data-aos="fade-up" data-aos-duration="1000">
            <ContentCard>
              <MainH4>
                Learn about ongoing projects, or see information we’ve
                aggregated in the LymeMIND Commons.
              </MainH4>
              <ContentButtons>
                <BtnWrapMobile>
                  <ButtonVisit to="/research-projects" secondary={"A"}>
                    <span>Research Projects</span>
                  </ButtonVisit>
                  <ViewEventBtn
                    to="https://commons.lymemind.org/#/MetadataSearch"
                    primary={"A"}
                    as="a"
                    target="_blank"
                    href="https://commons.lymemind.org/#/MetadataSearch"
                  >
                    <span>View Commons Site</span>
                  </ViewEventBtn>
                </BtnWrapMobile>
              </ContentButtons>
            </ContentCard>
            <Column>
              {/* <NewInfoText
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <MainParagraph>
                  Attend the 5th Annual LymeMIND Virtual Conference 2020
                </MainParagraph>
                <TextLinkWrap>
                  <MainTextLink gradient={"green"} to="/upcoming-event">
                    View Event Details
                  </MainTextLink>
                </TextLinkWrap>
              </NewInfoText> */}
              <NewInfoText
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="800"
              >
                <MainParagraph>
                  Part of the consortium? Learn about the consortia event that
                  takes place during the annual LymeMIND Conference.
                </MainParagraph>
                <TextLinkWrap>
                  <MainTextLink gradient={"green"} to="/consortium">
                    View Consortium
                  </MainTextLink>
                </TextLinkWrap>
              </NewInfoText>
            </Column>
          </ContentGrid>
        </Fragment>
      </DefaultWrapper>
    </Wrapper>
  )
}

export default NewInfo
