import styled from "styled-components"
import { BREAKPOINT } from "../../../utils/constants";

import {
  MainH2,
  MainParagraph,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  text-align: center;
  padding: 100px 0 80px;
  position:relative;
  @media (max-width: ${BREAKPOINT}) {
    padding: 47px 35px 58px;
  }
`

export const Title = styled(MainH2)`
  background: ${props =>
    props.gradient ? props.gradient : props.theme.greenGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  max-width: 640px;
  font-size: 40px;
  line-height: 48px;
  margin: 0 auto 48px;


  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 16px;
  }
`
export const Text = styled(MainParagraph)`
  max-width: 614px;
  margin: 0 auto 126px;
  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 80px;
  }
`
