import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import {
  Wrapper,
  ColumnTexture,
  WhoInfo,
  WhoImg,
  Title,
  Subtitle,
  CaretTitle,
  TitleWrap,
} from "./style"

import { MainParagraph } from "../../../utils/GlobalComponents"

const Who = () => {
  const data = useStaticQuery(graphql`
    query AboutWho {
      file(relativePath: { eq: "about/who-image.jpg" }) {
        publicURL
      }
    }
  `)

  return (
    <Wrapper>
      <Fragment>
        <ColumnTexture
          data-aos="fade"
          data-aos-duration="1000"
          data-aos-delay="500"
        />
        <WhoInfo>
          <TitleWrap>
            <Title data-aos="fade-up" data-aos-duration="1000">
              Who is LymeMIND for?
            </Title>
            <CaretTitle />
          </TitleWrap>

          <div>
            <Subtitle data-aos="fade-up" data-aos-duration="1000">
              For researchers, consortium members, sponsors
            </Subtitle>
            <WhoImg
              mobile
              data-aos="fade-up"
              data-aos-duration="1000"
              src={data.file.publicURL}
              alt="LymeMIND"
            />
            <MainParagraph data-aos="fade-up" data-aos-duration="1000">
              The goal of LymeMIND is to develop an integrated knowledge base to
              help Lyme disease researchers identify novel biomarkers and
              therapeutic target candidates.
              <br />
              <br />
              We bring together leaders in Lyme disease research through
              collaborations, an integrative web portal, and an annual
              conference.
            </MainParagraph>
          </div>
        </WhoInfo>
        <WhoImg
          data-aos="fade"
          data-aos-duration="1000"
          data-aos-delay="500"
          src={data.file.publicURL}
          alt="LymeMIND"
        />
      </Fragment>
    </Wrapper>
  )
}

export default Who
