import React, { Fragment, useEffect, useState } from "react"
import { useStaticQuery } from "gatsby"
import { useMediaQuery } from "react-responsive"

import { Wrapper, Title, Subtitle, ContentText, Paragraph } from "./style"
import Blob from "../../../components/common/Blob/Blob"
import Avatars from "./Avatars/Avatars"

const Hero = () => {
  const [isMobile, setIsMobile] = useState(false)

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1199px)",
  })

  useEffect(() => {
    if (window.innerWidth <= 400) {
      setIsMobile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="1000">
      <Fragment>
        <Blob
          toTop={
            isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "15%" : "0%"
          }
          toRight={
            isTabletOrMobileDevice ? "15%" : isNotebookOrIpad ? "15%" : "14%"
          }
          customWidth={
            isTabletOrMobileDevice ? "15vw" : isNotebookOrIpad ? "15vw" : "43vw"
          }
          customHeight={
            isTabletOrMobileDevice ? "40vw" : isNotebookOrIpad ? "46vw" : "46vw"
          }
        />

        <Blob
          toTop={
            isTabletOrMobileDevice ? "15%" : isNotebookOrIpad ? "15%" : "52%"
          }
          toRight={
            isTabletOrMobileDevice ? "120%" : isNotebookOrIpad ? "0" : "18%"
          }
          customWidth={
            isTabletOrMobileDevice
              ? "40vw"
              : isNotebookOrIpad
                ? "8.6vw"
                : "8.6vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "40vw"
              : isNotebookOrIpad
                ? "9.3vw"
                : "9.3vw"
          }
        />

        <Blob
          toTop={
            isTabletOrMobileDevice ? "15%" : isNotebookOrIpad ? "15%" : "52%"
          }
          toRight={
            isTabletOrMobileDevice ? "15%" : isNotebookOrIpad ? "15%" : "35%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "15vw" : "3vw"
          }
          customHeight={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "3.1vw" : "3.1vw"
          }
        />

        <Blob
          toTop={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "15%" : "31%"
          }
          toLeft={
            isTabletOrMobileDevice
              ? "40vw"
              : isNotebookOrIpad
                ? "300px"
                : "250px"
          }
          customWidth={
            isTabletOrMobileDevice ? "75vw" : isNotebookOrIpad ? "5vw" : "5vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "40vw"
              : isNotebookOrIpad
                ? "5.4vw"
                : "5.4vw"
          }
        />

        <Blob
          toTop={
            isTabletOrMobileDevice ? "50%" : isNotebookOrIpad ? "15%" : "11%"
          }
          toLeft={
            isTabletOrMobileDevice
              ? "-170px"
              : isNotebookOrIpad
                ? "100px"
                : "-170px"
          }
          customWidth={
            isTabletOrMobileDevice ? "40vw" : isNotebookOrIpad ? "15vw" : "26vw"
          }
          customHeight={
            isTabletOrMobileDevice ? "40vw" : isNotebookOrIpad ? "50vw" : "28vw"
          }
        />

        <Avatars />
      </Fragment>

      <Fragment>
        <Title>About LymeMIND</Title>
        <Subtitle
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
        >
          Lyme disease infects an estimated 300,000 people each year in the United States
        </Subtitle>
        <ContentText>
          <Paragraph
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={isMobile ? "0" : "1200"}
          >
            The disease, caused by bacteria and infected ticks, is known to be profoundly complex, involving multiple tissues and organs systems that result in complex interactions between host and pathogen.
          </Paragraph>
          <Paragraph
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={isMobile ? "0" : "1500"}
          >
            The natural history of disease is also complex, with an acute infection that mimics signs of other illnesses and often leading to chronic neurological, immune, and musculoskeletal dysfunction.
          </Paragraph>
        </ContentText>
      </Fragment>
    </Wrapper>
  )
}

export default Hero
