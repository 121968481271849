import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/About/Hero"
import Solution from "../components/About/Solution"
import Mind from "../components/About/Mind"
import Who from "../components/About/Who"
import Patients from "../components/About/Patients"
import AboutRegister from "../components/About/AboutRegister"
import NewInfo from "../components/About/NewInfo"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <Hero />
      <Solution />
      <Mind />
      <Who />
      <NewInfo />
      <Patients />
      <AboutRegister />
    </Layout>
  )
}

export default AboutPage
