import styled from "styled-components"
import { BREAKPOINT, LG, XXL } from "../../../utils/constants"
import BgTextureColors from "../../../images/texture-colors.jpg"
import BgTextureColorsMobile from "../../../images/texture-colors-mobile.jpg"
import { MainH3 } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  padding: 160px 0 142px;
  position: relative;
  @media (max-width: ${BREAKPOINT}) {
    padding: 50px 35px;
  }
`

export const ContentMindGrid = styled.div`
  background: url(${BgTextureColors}) no-repeat center center / cover;
  max-width: 1256px;
  padding: 0 94px;
  margin: 0 auto;
  @media (min-width: ${XXL}) {
    max-width: none;
    width: calc(100% - 184px);
  }
  @media (max-width: ${BREAKPOINT}) {
    background: url(${BgTextureColorsMobile}) no-repeat center center / cover;
    padding: 42px 48px 18px;
  }
`

export const MindGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
  padding-bottom: 30px;
  div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  div:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  div:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }
  div:nth-child(4) {
    grid-area: 1 / 4 / 2 / 5;
  }
  @media (max-width: ${BREAKPOINT}) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    div {
      grid-area: auto !important;
    }
  }
`

export const Letter = styled.div`
  text-align: center;
  background: ${props => props.theme.veryLightNavy};
  height: 474px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: -64px;
  svg {
    display: block;
    margin: 80px auto 0;
    @media (max-width: ${BREAKPOINT}) {
      margin-top: 0;
    }
  }
  span {
    background: ${props => props.theme.neutralSilver};
    color: ${props => props.theme.neutralDark};
    line-height: 32px;
    border-radius: 20px;
    width: 137px;
    @media (min-width: ${BREAKPOINT}) and (max-width: ${LG}) {
      max-width: 110px;
    }
  }
  @media (max-width: ${BREAKPOINT}) {
    top: 0;
    height: 208px;
    padding: 10px 0;
  }
`
export const Subtitle = styled(MainH3)`
  max-width: 937px;
  margin: 100px auto;
  strong {
    color: ${props => props.theme.pink};
  }
  @media (min-width: ${BREAKPOINT}) and (max-width: ${LG}) {
    max-width: 664px;
  }
  @media (max-width: ${BREAKPOINT}) {
    text-align: center;
    margin: 70px 0 40px;
  }
`
export const Box = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  border-radius: 200px;
  max-width: 937px;
  height: 177px;
  margin: 0 auto 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  .desktop {
    display: block;
    margin: 0 58px 0 42px;
    @media (max-width: ${BREAKPOINT}) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: ${BREAKPOINT}) {
      display: block;
      margin: 30px 0;
      transform: rotate(90deg);
      span {
        display: flex;
        margin: 5px;
        svg {
          margin: 0 3px;
          &:nth-child(1) {
            opacity: 0.3;
          }
          &:nth-child(2) {
            opacity: 0.6;
          }
          &:nth-child(3) {
            opacity: 1;
          }
        }
      }
    }
  }
  svg {
    fill: url(#orangeGradient);
    margin: 0 7px;
    transform: rotate(-90deg);
    &:nth-child(1) {
      opacity: 0.2;
    }
    &:nth-child(2) {
      opacity: 0.4;
    }
    &:nth-child(3) {
      opacity: 0.6;
    }
  }
  @media (min-width: ${BREAKPOINT}) and (max-width: ${LG}) {
    max-width: 664px;
  }
  @media (max-width: ${BREAKPOINT}) {
    border-radius: 8px;
    height: 293px;
    flex-direction: column;
    padding: 0 50px;
    margin-bottom: 65px;
    & + div {
      padding: 0;
    }
  }
`

export const BoxText = styled(MainH3)`
  text-transform: uppercase;
  color: ${props => props.theme.neutralGreen};
  background: ${props =>
    props.gradient === "orange" ? props.theme.orangeGradient : "transparent"};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${props =>
    props.gradient === "orange" ? "transparent" : ""};
  @media (max-width: ${BREAKPOINT}) {
    font-size: 32px;
    line-height: 36px;
    font-weight: 500px;
    text-align: center;
  }
`

export const Title = styled(MainH3)`
  position: relative;
  z-index: 4;
  strong {
    color: ${props => props.theme.lightGreen};
  }
  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 30px;
  }
`
