import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { useStaticQuery } from "gatsby"
import { Wrapper, AvatarBlob } from "./style"

const Avatars = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const {
    allWordpressWpStories: { edges },
  } = useStaticQuery(graphql`
    query AvatarsQuery {
      allWordpressWpStories {
        edges {
          node {
            acf {
              video_thumbnail {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const [avatarImages, setAvatarImages] = useState([])

  useEffect(() => {
    edges.forEach(({ node }) =>
      setAvatarImages(prevArray => [
        ...prevArray,
        node.acf.video_thumbnail.source_url,
      ])
    )
  }, [edges])

  return (
    <Wrapper>
      <div>
        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "40%" : isNotebookOrIpad ? "26%" : "26%"
          }
          toRight={
            isTabletOrMobileDevice ? "90%" : isNotebookOrIpad ? "16%" : "16%"
          }
          customWidth={
            isTabletOrMobileDevice
              ? "20vw"
              : isNotebookOrIpad
              ? "7.5vw"
              : "7.5vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "20vw"
              : isNotebookOrIpad
              ? "7.2vw"
              : "7.2vw"
          }
          bg={avatarImages[28]}
          delay={"200"}
        />

        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "70%" : isNotebookOrIpad ? "70%" : "70%"
          }
          toRight={
            isTabletOrMobileDevice ? "100%" : isNotebookOrIpad ? "10%" : "10%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "5vw" : "5vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "0"
              : isNotebookOrIpad
              ? "4.8vw"
              : "4.8vw"
          }
          bg={avatarImages[13]}
          delay={"400"}
        />

        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "10%" : isNotebookOrIpad ? "10%" : "10%"
          }
          toRight={
            isTabletOrMobileDevice ? "50%" : isNotebookOrIpad ? "25%" : "25%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "5vw" : "5vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "0"
              : isNotebookOrIpad
              ? "4.9vw"
              : "4.9vw"
          }
          bg={avatarImages[23]}
          delay={"600"}
        />

        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "45%" : isNotebookOrIpad ? "54%" : "54%"
          }
          toLeft={
            isTabletOrMobileDevice
              ? "50%"
              : isNotebookOrIpad
              ? "300px"
              : "300px"
          }
          customWidth={
            isTabletOrMobileDevice
              ? "20vw"
              : isNotebookOrIpad
              ? "5.9vw"
              : "5.9vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "20vw"
              : isNotebookOrIpad
              ? "5.6vw"
              : "5.6vw"
          }
          bg={avatarImages[29]}
          delay={"800"}
        />

        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "10%" : isNotebookOrIpad ? "10%" : "10%"
          }
          toLeft={
            isTabletOrMobileDevice ? "90px" : isNotebookOrIpad ? "90px" : "90px"
          }
          customWidth={
            isTabletOrMobileDevice
              ? "15vw"
              : isNotebookOrIpad
              ? "6.8vw"
              : "6.8vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "15vw"
              : isNotebookOrIpad
              ? "6.5vw"
              : "6.5vw"
          }
          bg={avatarImages[31]}
          delay={"1000"}
        />

        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "55%" : isNotebookOrIpad ? "60%" : "60%"
          }
          toLeft={
            isTabletOrMobileDevice ? "100px" : isNotebookOrIpad ? "70px" : "70px"
          }
          customWidth={
            isTabletOrMobileDevice
              ? "15vw"
              : isNotebookOrIpad
              ? "5.1vw"
              : "5.1vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "15vw"
              : isNotebookOrIpad
              ? "4.9vw"
              : "4.9vw"
          }
          bg={avatarImages[32]}
          delay={"1200"}
        />

        <AvatarBlob
          toTop={
            isTabletOrMobileDevice ? "85%" : isNotebookOrIpad ? "85%" : "85%"
          }
          toLeft={
            isTabletOrMobileDevice ? "40px" : isNotebookOrIpad ? "40px" : "40px"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "5vw" : "5vw"
          }
          customHeight={
            isTabletOrMobileDevice
              ? "0"
              : isNotebookOrIpad
              ? "4.8vw"
              : "4.8vw"
          }
          bg={avatarImages[11]}
          delay={"1400"}
        />
      </div>
    </Wrapper>
  )
}

export default Avatars
