import styled from "styled-components"
import { LG, XL } from "../../../utils/constants"

import {
  SupTitle,
  MainH3,
  MainParagraph,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;

  @media (max-width: ${XL}) {
    grid-gap: 40px;
    padding: 0 30px;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  padding: 40px 40px;
  border-radius: 8px;

  @media (max-width: ${LG}) {
    padding: 20px 15px;
  }
`

export const TopTitle = styled(SupTitle)`
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 55px;
  display: block;

  @media (max-width: ${LG}) {
    margin-left: 35px;
  }
`

export const Title = styled(MainH3)`
  margin-bottom: 15px;
  font-weight: 500;
  background: ${props =>
    props.gradient ? props.gradient : props.theme.orangeGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Paragraph = styled(MainParagraph)`
  max-width: 400px;
  height: 225px;
  overflow: hidden;

  @media (max-width: ${LG}) {
    height: auto;
    margin-bottom: 30px;
    max-width: unset;
  }
`

export const ButtonVisit = styled(SecondaryBtn)`
`
