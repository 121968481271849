import React, { Fragment, useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import {
  Wrapper,
  ContentGrid,
  ContentCard,
  ContentButtons,
  BtnWrapMobile,
  LastBtnWrap,
} from "./style"
import { DefaultWrapper, MainH4 } from "../../../utils/GlobalComponents"
import { ButtonVisit } from "../../Home/HomeResearch/style"
import { ViewEventBtn } from "../../Home/Hero/style"
import RegisterModal from "../../RegisterModal/RegisterModal"

const AboutRegister = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 375) {
      setIsMobile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <Wrapper>
      <RegisterModal visible={modalOpen} onClose={() => handleModalOpen()} />
      <DefaultWrapper>
        <Fragment>
          <ContentGrid>
            <ContentCard data-aos="fade-up" data-aos-duration="1000">
              <MainH4>
                {/* Be a part of the LymeMIND community and connect with the latest
                research. View highlights of the 2020 LymeMIND Conference */}
                Connect with the latest research. View highlights of the 2020 LymeMIND Conference.
              </MainH4>
              <ContentButtons>
                <BtnWrapMobile>
                  {/* <ViewEventBtn
                    to=""
                    as="button"
                    onClick={() => handleModalOpen(true)}
                    primary={"A"}
                  >
                    <span>Register Now</span>
                  </ViewEventBtn> */}
                  <ButtonVisit to="/past-event-2020" secondary={"A"}>
                    <span>View Event Details</span>
                  </ButtonVisit>
                </BtnWrapMobile>
              </ContentButtons>
            </ContentCard>
            {/* <ContentCard
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={isMobile ? "0" : "500"}
            >
              <MainH4>
                Those having symptoms of Lyme disease should have their stories
                heard. LymeMIND is sharing their stories through interviews.
              </MainH4>
              <ContentButtons>
                <LastBtnWrap>
                  <ViewEventBtn to="/stories" secondary={"B"}>
                    <span>See LymeMIND Stories</span>
                  </ViewEventBtn>
                </LastBtnWrap>
              </ContentButtons>
            </ContentCard> */}
          </ContentGrid>
        </Fragment>
      </DefaultWrapper>
    </Wrapper>
  )
}

export default AboutRegister
