import styled from "styled-components"
import { BREAKPOINT, XXL, XL, LG } from "../../../utils/constants"
import { MainH2, MainH3 } from "../../../utils/GlobalComponents"
import BgTextureColors from "../../../images/texture-colors-1.jpg"
import BgTextureColors2 from "../../../images/texture-colors-2.jpg"

export const Wrapper = styled.div`
  display: flex;
  min-height: 500px;
  /*   overflow: hidden; */
  position: relative;
  @media (max-width: ${BREAKPOINT}) {
    display: block;
    min-height: unset;
  }
`

export const ColumnTexture = styled.div`
  background-image: url(${props =>
    props.texture === "2" ? BgTextureColors2 : BgTextureColors});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  @media (max-width: ${BREAKPOINT}) {
    display: none;
  }
`
export const WhoInfo = styled.div`
  display: flex;
  flex-direction: column;
  & div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${BREAKPOINT}) {
      display: block;
      padding: 18px 0;
    }
  }
  @media (min-width: ${XXL}) {
    padding: 0 8rem 0 9rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: ${XXL}) {
    flex: 0 0 55%;
    max-width: 55%;
    h2,
    h3,
    p {
      max-width: 514px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: ${XL}) {
    h2 {
      margin-bottom: 40px;
    }
    h2,
    h3,
    p {
      width: 360px;
    }
  }
  @media (max-width: ${BREAKPOINT}) {
    display: block;
    flex: none;
    max-width: none;
    h2,
    h3,
    p {
      width: 100%;
      max-width: none;
      padding: 0 35px;
    }
    p {
      p {
        padding: 0;
      }
    }
  }
`

export const WhoImg = styled.img`
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  object-fit: cover;
  display: ${props => (props.mobile ? "none" : "block")};
  @media (max-width: ${BREAKPOINT}) {
    display: ${props => (props.mobile ? "block" : "none")};
    flex: 0 0 100%;
    max-width: 100%;
    max-height: 267px;
    width: 100%;
    margin-bottom: 45px;
  }
`

export const TitleWrap = styled.div`
  width: max-content;
  /*   align-self: center; */

  @media (max-width: ${XXL}) {
    margin: 0 auto;
  }

  @media (max-width: ${LG}) {
    margin-left: 30px;
    width: 100%;
  }
`

export const Title = styled(MainH2)`
  max-width: none;
  display: -webkit-box;
  line-height: 54px !important;

  @media (max-width: ${LG}) {
    margin-bottom: 15px !important;
    margin: 0 auto;
    padding-left: 0 !important;
  }
`
export const CaretTitle = styled.div`
  display: block !important;
  line-height: 43px;
  width: 40px;
  height: 4px;
  background: ${props => props.theme.orangeGradient};
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 0 !important;
  flex-grow: 0 !important;
`

export const Subtitle = styled(MainH3)`
  font-weight: 500;
  margin-bottom: 30px;
`
