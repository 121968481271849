import styled from "styled-components"
import { LG, XL } from "../../../utils/constants"
import {
  SupTitle,
  MainH2,
  MainParagraph,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  position: relative;
`

export const GridWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${props => props.theme.neutralSilver};
  align-items: center;
  padding: 140px 0;
  justify-items: center;

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${LG}) {
    display: flex;
    flex-direction: column-reverse;
    padding: 100px 30px 60px;
    justify-items: flex-start;
    overflow: hidden;
  }

  > div {
    &:first-child {
      margin-left: 100px;
      position: relative;

      @media (max-width: ${XL}) {
        margin-left: 0;
        margin-bottom: 40px;
      }
      @media (max-width: ${LG}) {
        padding-left: 0;
        margin-bottom: 40px;
      }
    }
  }
`

export const TopTitle = styled(SupTitle)`
  @media (max-width: ${LG}) {
    display: none;
  }
`

export const Title = styled(MainH2)`
  max-width: unset;
  line-height: normal;
  margin-bottom: 20px;

  @media (max-width: ${LG}) {
    line-height: normal;
    padding: 0;
  }
`

export const Text = styled(MainParagraph)`
  max-width: 423px;
  margin-bottom: 30px;

  @media (max-width: ${LG}) {
    max-width: unset;
    padding: 0;
  }
`

export const TeamButton = styled(SecondaryBtn)``

export const HeroImage = styled.div`
  display: grid;
  grid-template-columns: 160px 160px 160px 160px;
  grid-template-rows: 1fr;
  align-items: center;
  padding-right: 50px;

  @media (max-width: ${LG}) {
    grid-template-columns: repeat(4, 90px);
    padding: 0 10px;
    margin-bottom: 30px;
  }
`

export const TinyImage = styled.div`
  background-color: ${props => (props.transparent ? "transparent" : "white")};
  display: inline-block;
  box-shadow: ${props => (props.transparent ? "none" : props.theme.boxShadow)};
  border-radius: 4px;

  width: 136px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  @media (max-width: ${LG}) {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
  img {
    max-width: 83px;
    max-height: 80px;

    @media (max-width: ${LG}) {
      max-width: 50px;
      max-height: 48px;
    }
  }
`
